import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../components/layout';

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<h1>Hi people</h1>
			<p>Welcome to your new Gatsby site.</p>
			<p>Now go build something great.</p>
			<ul>
				<li>
					<Link to="/page-2/">Go to page 2</Link>
				</li>
				<li>
					<Link to="/home/">Go to home</Link>
				</li>
			</ul>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

export default IndexPage;
